<template>
  <b-modal
    :id="'performerRequestTypeEdit'"
    title="Изменить тип запроса исполнителя"
    centered
    size="sm" 
    hide-footer 
    no-enforce-focus  
    :no-close-on-backdrop="true"
    @show="performerRequestTypeEdit"
    @close="closeModal"
        >
        <b-row>
            <b-col>
                <b-row>
                    <b-col>
                        <label>Наименование<i class="color">*</i></label>
                        <b-form-group>
                                <b-input size="sm" v-model="performerRequestTypeCreat.name"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Код<i class="color">*</i></label>
                        <b-form-group>
                            <b-input size="sm" v-model="performerRequestTypeCreat.code" type="number"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Родительский тип</label>
                        <v-select
                        label="name"
                        v-model="performerRequestTypeCreat.parent_id"
                        :options="performerRequestTypesDatas.pr_types   "
                        :reduce="item => item.id"
                        >
                        </v-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Предварительный назначение на заказ<i class="color">*</i></label>
                        <b-form-group>
                            <b-input size="sm" v-model="performerRequestTypeCreat.pre_assignment_order" type="number"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="d-flex justify-content-between">
                <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                    Отмена
                </b-button>
                <b-button
                :disabled="submitButtonDisabled"
                class="mt-2 col-md-5"
                variant="primary"
                @click="EditPerformerRequestType">
                    Изменить
                </b-button>
            </b-col>
        </b-row>
  </b-modal>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        VSelect,
    },
    props:  ['performerRequestTypesDatas', 'id'],
    data() {
        return {
            performerRequestTypeCreat: {},
            submitButtonDisabled: false,
        }
    },
    methods: {
        performerRequestTypeEdit() {
            this.$http
                .get(`performer-request-types/${this.id}/edit`)
                .then(res=> {
                    this.performerRequestTypeCreat = res.data
                })
        },
        closeModal() {
            this.$bvModal.hide('performerRequestTypeEdit')
            this.performerRequestTypeCreat = {}
        },
        EditPerformerRequestType() {
            this.submitButtonDisabled = true
            this.$http
                .patch(`performer-request-types/${this.id}`, this.performerRequestTypeCreat)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: res.data.message,
                        },
                    })
                    this.closeModal()
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
        }
    }
}
</script>

<style scoped>
.color {
    color: red;
}
</style>