<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
       <table-performer-request-type :performerRequest="performerRequest" @editForm="editForm"/>
       <modal-performer-request-type @refresh="refresh" :performerRequestTypesDatas="performerRequestTypesDatas"/>
       <modal-performer-request-type-edit @refresh="refresh" :performerRequestTypesDatas="performerRequestTypesDatas" :id="id" />
    </div>
</div>
</template>

<script>
import tablePerformerRequestType from "@/views/component/Table/tablePerformerRequestType.vue"
import ModalPerformerRequestType from '@/views/component/Modal/ModalPerformerRequestType/ModalPerformerRequestType.vue'
import ModalPerformerRequestTypeEdit from '@/views/component/Modal/ModalPerformerRequestType/ModalPerformerRequestTypeEdit.vue'
export default {
    components: {
        tablePerformerRequestType,
        ModalPerformerRequestType,
        ModalPerformerRequestTypeEdit,
    },
    data() {
        return {
            showPreloader: false,
            performerRequest: [],
            performerRequestTypesDatas: {},
            id: null,
        }
    },
    mounted() {
        this.performerRequestTypesData()
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        refresh(){
            this.showPreloader = true
            this.$http.get(`${this.$route.name}`)
                .then(res => {
                    this.performerRequest = res.data
                    this.$store.commit('pageData/setdataCount', this.performerRequest.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        performerRequestTypesData() {
            this.$http
                .get('performer-request-types/data')
                .then(res => {
                    this.performerRequestTypesDatas = res.data
                })
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.performerRequest = res.data
                        this.$store.commit('pageData/setdataCount', this.performerRequest.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.refresh()
            }
        },
        editForm(id) {
            this.id = id
            setTimeout(this.openModalPerformerRequestTypeEdit, 0)
        },
        openModalPerformerRequestTypeEdit() {
            this.$bvModal.show('performerRequestTypeEdit')
        }
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val) {
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.performerRequest = res.data
                    this.$store.commit('pageData/setdataCount', this.performerRequest.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
             }
         }
    }
}
</script>